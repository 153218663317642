// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-template-js": () => import("/opt/build/repo/src/templates/serviceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-service-online-js": () => import("/opt/build/repo/src/templates/serviceOnline.js" /* webpackChunkName: "component---src-templates-service-online-js" */),
  "component---src-templates-service-in-person-js": () => import("/opt/build/repo/src/templates/serviceInPerson.js" /* webpackChunkName: "component---src-templates-service-in-person-js" */),
  "component---src-templates-toolbox-template-js": () => import("/opt/build/repo/src/templates/toolboxTemplate.js" /* webpackChunkName: "component---src-templates-toolbox-template-js" */),
  "component---src-templates-blog-template-js": () => import("/opt/build/repo/src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-event-template-js": () => import("/opt/build/repo/src/templates/eventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-nikki-js": () => import("/opt/build/repo/src/pages/about-nikki.js" /* webpackChunkName: "component---src-pages-about-nikki-js" */),
  "component---src-pages-events-js": () => import("/opt/build/repo/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-getting-started-js": () => import("/opt/build/repo/src/pages/getting-started.js" /* webpackChunkName: "component---src-pages-getting-started-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("/opt/build/repo/src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-services-js": () => import("/opt/build/repo/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-toolbox-js": () => import("/opt/build/repo/src/pages/toolbox.js" /* webpackChunkName: "component---src-pages-toolbox-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

